<template>
  <div class="host">
    <div class="comp-top-area">
      <img src="https://mamab.jp/uploads/web_image/senior_thanks_mv.png" />
    </div>
    <div class="other-area">
      <header>
        <img
          src="https://mamab.jp/uploads/web_image/senior_subpick_title.png"
        />
      </header>
      <div class="banner-wrap">
        <div class="box-company-delivery">
          <a href="https://mamab.jp/r/x">
            <img
              src="https://mamab.jp/uploads/web_image/buysell_complete_bnr.png"
            />
          </a>
        </div>
      </div>
    </div>
    <div><img src="https://mamab.jp/uploads/web_image/kan_cv_h.png" /></div>
    <div class="present-area-btn">
      <ul>
        <li>
          <a href="#" v-scroll-to="'#karadanote-present-area'">
            <img
              src="https://mamab.jp/uploads/web_image/kan_cv_karadanotebtn.png"
            />
          </a>
        </li>
        <li>
          <a href="#" v-scroll-to="'#hoken-area'">
            <img src="https://mamab.jp/uploads/web_image/kan_cv_other.png" />
          </a>
        </li>
      </ul>
    </div>
    <div id="karadanote-present-area" class="karadanote-present-area">
      <img src="https://mamab.jp/uploads/web_image/senior_karadanote.png" />
    </div>
    <div id="hoken-area" class="hoken-area">
      <img src="https://mamab.jp/uploads/web_image/senior_thanks_hoken.png" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
div {
  &.host {
    padding-top: 50px;
  }

  &.other-area {
    background-color: #d8dde7;
    padding-bottom: 10px;
  }

  &.banner-wrap {
    background: #fff;
    margin: 10px;
    padding: 10px 15px;
  }

  &.present-area-btn {
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: center;

      li {
        width: 32.5%;
        margin-right: 5px;

        :last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.modal-link-btn-area {
  background: #fffa99;
  text-align: center;
}
.modal-link-btn-img {
  width: 80%;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s;
}

.modal-leave-active {
  transition: opacity 0.4s ease 0.2s;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
